<template>
  <el-container>
    <el-header>
      <div>
        <div class="nav-menu">
          <img
            @click="gotoWebMain"
            :src="require(`../assets/logo/logo-white.png`)"
            class="logo"
          />
          <div class="menu">
            <div class="language">
              <span @click="$i18n.locale = 'th'" class="select">ไทย </span>
              <span>|</span>
              <span @click="$i18n.locale = 'en'" class="select"> EN</span>
            </div>
            <font-awesome-icon
              class="nav-icon"
              @click="dialogVisible = true"
              icon="fa-solid fa-bars"
            />
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link cursor-pointer">
                <div class="user-box">
                  <avatar
                    :rounded="true"
                    :username="user.email"
                    :src="`${user.profile_photo_url}`"
                    :size="30"
                  ></avatar>
                  <span> {{ user.name }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="profile">{{
                  $t("message.menuProfile")
                }}</el-dropdown-item>
                <el-dropdown-item
                  v-if="
                    surveyType == 'casual' &&
                    (user.type === 'super-admin' || user.type === 'admin')
                  "
                  command="responseReport"
                  >{{ $t("message.responseReport") }}</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="
                    surveyType == 'casual' &&
                    (user.type === 'super-admin' || user.type === 'admin')
                  "
                  command="questionReport"
                  >{{ $t("message.questionReport") }}</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="
                    surveyType == 'casual' &&
                    (user.type === 'super-admin' || user.type === 'admin')
                  "
                  command="statisticsReport"
                  >{{ $t("message.statisticsReport") }}</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="
                    surveyType == 'casual' &&
                    (user.type === 'super-admin' || user.type === 'admin')
                  "
                  command="statisticsPeriodReport"
                  >{{ $t("message.statisticsPeriodReport") }}</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="surveyType == 'casual' && user.type === 'super-admin'"
                  command="setting"
                  >{{ $t("message.setting") }}</el-dropdown-item
                >
                <el-dropdown-item command="signOut">{{
                  $t("message.logout")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="title-box">
          <h1>{{ title }}</h1>
          <img :src="require(`../assets/image/image-title-casual.png`)" />
        </div>
      </div>
    </el-header>
    <el-main>
      <el-breadcrumb v-if="breadcrumbs.length > 0" separator="/">
        <el-breadcrumb-item
          v-for="(item, index) in breadcrumbs"
          :key="index"
          :to="{ path: item.path }"
          >{{
            item.i18n !== undefined
              ? $t(`breadcrums.${item["name"]}`)
              : item.name
          }}</el-breadcrumb-item
        >
      </el-breadcrumb>
      <slot></slot
    ></el-main>

    <el-dialog title="" :visible.sync="dialogVisible" :fullscreen="true">
      <div>
        <div class="title-logo">
          <img :src="require(`../assets/logo/logo-color.png`)" class="logo" />
        </div>

        <div class="list-menu">
          <p @click="handleCommand('profile')">
            {{ $t("message.menuProfile") }}
          </p>
          <p
            v-if="
              surveyType == 'casual' &&
              (user.type === 'super-admin' || user.type === 'admin')
            "
            @click="handleCommand('responseReport')"
          >
            {{ $t("message.responseReport") }}
          </p>
          <p
            v-if="
              surveyType == 'casual' &&
              (user.type === 'super-admin' || user.type === 'admin')
            "
            @click="handleCommand('questionReport')"
          >
            {{ $t("message.questionReport") }}
          </p>
          <p
            v-if="
              surveyType == 'casual' &&
              (user.type === 'super-admin' || user.type === 'admin')
            "
            @click="handleCommand('statisticsReport')"
          >
            {{ $t("message.statisticsReport") }}
          </p>
          <p
            v-if="
              surveyType == 'casual' &&
              (user.type === 'super-admin' || user.type === 'admin')
            "
            @click="handleCommand('statisticsPeriodReport')"
          >
            {{ $t("message.statisticsPeriodReport") }}
          </p>
          <p
            v-if="surveyType == 'casual' && user.type === 'super-admin'"
            @click="handleCommand('setting')"
          >
            {{ $t("message.setting") }}
          </p>
          <p @click="handleCommand('signOut')">{{ $t("message.logout") }}</p>

          <div class="language">
            <span @click="$i18n.locale = 'th'" class="select">ไทย </span>
            <span>|</span>
            <span @click="$i18n.locale = 'en'" class="select"> EN</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import Avatar from "vue-avatar";

export default {
  components: {
    Avatar,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    },
    surveyType() {
      return this.$store.state.surveyType;
    },
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      urlMain: process.env.VUE_APP_BASE_URL_WEB_MAIN,
      dialogVisible: false,
    };
  },
  methods: {
    handleCommand(command) {
      if (command === "signOut") {
        this.signOut();
      } else if (command === "profile") {
        window.location.href = `${this.urlMain}/profile`;
      } else if (command === "responseReport") {
        this.$router.push("/response/report").catch({});
      } else if (command === "questionReport") {
        this.$router.push("/question/report").catch({});
      } else if (command === "statisticsReport") {
        this.$router.push("/statistics/report").catch({});
      } else if (command === "statisticsPeriodReport") {
        this.$router.push("/statistics/period/report").catch({});
      } else if (command === "setting") {
        this.$router.push("/setting").catch({});
      }
    },
    gotoWebMain() {
      window.location.href = "https://mindanalytica.com/";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/element-variables.scss";

.el-container {
  header {
    height: 270px !important;
    background-color: $--color-primary;
    padding: 20px 10vw;
    border-bottom-right-radius: 60px;
    border-bottom-left-radius: 60px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 500px) {
      height: 215px !important;
      padding: 20px;
    }

    .nav-menu {
      padding-bottom: 20px;
      .logo {
        height: 60px;
        cursor: pointer;
      }

      display: flex;
      justify-content: space-between;

      h1 {
        margin: 0;
        color: #fff;
      }

      .menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;

        .language {
          margin: 0 20px;
          color: #fff;

          @media screen and (max-width: 500px) {
            display: none;
          }
          .select {
            cursor: pointer;
            &:hover {
              color: #ffffffbf;
            }
          }
        }

        .nav-icon {
          color: #fff;
          font-size: 20px;
          display: none;
          @media screen and (max-width: 500px) {
            display: block;
          }
        }

        .user-box {
          background-color: #fff;
          border-radius: 40px;
          padding: 3px 5px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 500px) {
            display: none;
          }
          span {
            padding-left: 5px;
          }
          i {
            padding-left: 1px;
          }
        }
      }
    }

    .title-box {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 500px) {
        position: relative;
      }
      h1 {
        font-size: 50px;
        color: #fff;
        padding-left: 30px;
        @media screen and (max-width: 500px) {
          font-size: 28px;
        }
      }
      img {
        height: 200px;
        @media screen and (max-width: 500px) {
          height: 130px;
          position: absolute;
          right: 0;
          top: 10px;
        }
      }
    }
  }
  .el-main {
    min-height: 77vh;
    padding: 40px 10vw;
  }

  .el-dialog {
    .title-logo {
      text-align: center;
      padding-bottom: 10px;
      .logo {
        height: 60px;
        cursor: pointer;
      }
    }

    .list-menu {
      p {
        text-align: center;
        cursor: pointer;
        &:hover {
          color: $--color-primary;
        }
      }

      .language {
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
